


































import { ModalTransactionEditTs } from './ModalTransactionEditTs';
export default class ModalTransactionEdit extends ModalTransactionEditTs {}
