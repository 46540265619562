






































































































// @ts-ignore
import { FormAggregateTransactionTs } from './FormAggregateTransactionTs';
export default class FormAggregateTransaction extends FormAggregateTransactionTs {}
